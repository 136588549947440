import { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import {
  TextField,
  InputAdornment,
  IconButton,
  Card,
  CardContent,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import logo from '../logo.blue.png'
import AuthContext from '../store/auth.context'
import { ConfigConst, ApiRoutes } from '../config'
import { useNavigate, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Helmet } from 'react-helmet'
const LoginScreen = () => {
  const { register, handleSubmit } = useForm()

  const { apiend } = ConfigConst

  const { login } = ApiRoutes

  const [btnLoad, setBtnLoad] = useState(false)

  const [showPasswd, setShowPasswd] = useState(false)

  const auth = useContext(AuthContext)

  const navigate = useNavigate()

  const location = useLocation()

  const toggleShowPasswd = () => setShowPasswd((prevState) => !prevState)

  const _initLogin = async (formdata) => {
    setBtnLoad(true)
    try {
      const response = await fetch(`${apiend}${login}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(formdata),
      })
      const result = await response.json()
      if (!!result.error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: result.error.message,
        })
      } else {
        const { token } = result
        auth.login(token)
        auth.storeUser({ marital_status: 'Single' })
        if (!!location.state) {
          navigate(location.state.from, { replace: true })
        } else {
          navigate('/dashboard', { replace: true })
        }
      }
      setBtnLoad(false)
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Something Went Wrong !',
      })
      setBtnLoad(false)
    }
  }
  return (
    <section className="loginscreen">
      <Helmet>
        <title>Login - Bee International Client Portal</title>
      </Helmet>
      <div className="container mx-auto h-full flex justify-center items-center">
        <div className="login-form-holder">
          <Card variant="elevation">
            <CardContent>
              <img src={logo} className="login-logo" alt="BeeInternational" />
              <form onSubmit={handleSubmit(_initLogin)}>
                <TextField
                  fullWidth
                  margin="normal"
                  {...register('email', { required: true })}
                  label="Email Id"
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  margin="normal"
                  {...register('password', { required: true })}
                  label="Password"
                  variant="outlined"
                  type={showPasswd ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={toggleShowPasswd}
                          edge="end"
                        >
                          {showPasswd ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="inline-block w-full h-3"></div>
                <LoadingButton
                  loading={btnLoad}
                  fullWidth
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  size="large"
                  className="h-12"
                >
                  Login Now
                </LoadingButton>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  )
}

export default LoginScreen
