import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material'
import { CookiesProvider } from 'react-cookie'
import ErrorBoundary from './ErrorBoundary'
const theme = createTheme({
  typography: {
    fontFamily: ['"Inter", sans-serif'].join(','),
    fontSize: 13,
  },
  palette: {
    primary: {
      main: '#04164A',
      light: '#FFF',
    },
    success: {
      main: '#2e7d32',
      ligh: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#1b5e20',
    },
  },
  components: {
    // Inputs
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff", 
          "& .MuiOutlinedInput-notchedOutline": {
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
            }
          }
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        color: '#000',
        fontWeight: "bold"
      },
    },
  },

})
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <BrowserRouter>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
          <App />
          </ErrorBoundary>
        </ThemeProvider>
      </CookiesProvider>
    </BrowserRouter>
  </>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
