import { useState, useContext } from 'react'
import AuthContext from '../store/auth.context'
import { ConfigConst } from '../config/constants'
import { useNavigate } from 'react-router-dom'
import FormStepContext from '../store/form.step.context'

const useHttp = (handleResponse) => {
  const context = useContext(AuthContext)
  const formCtx = useContext(FormStepContext)

  const { token, user } = context
  const { apiend } = ConfigConst
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const sendRequest = async (reqConfig) => {
    const { fullLoader = true, message = true } = reqConfig
    setIsLoading(true)
    if (fullLoader) {
      context.initLoading()
    }
    const lead_id = user ? user.leadId : '';
    try {
      const resp = await fetch(`${apiend}${reqConfig.url}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        method: reqConfig.method ? reqConfig.method : 'GET',
        body: reqConfig.body
          ? JSON.stringify({ ...reqConfig.body, lead_id })
          : null,
      })
      const result = await resp.json()
      if (message && !!reqConfig.method) {
        formCtx.showToast({ message: result.status, status: 'success' })
      }
      handleResponse(result)
      setIsLoading(false)
      context.resetLoading()
    } catch (error) {
      setError(error)
      if (message && !!reqConfig.method) {
        formCtx.showToast({ message: 'Something went Wrong!', status: 'error' })
      }
      setIsLoading(false)
      context.resetLoading()
      formCtx.resetToast()
    }
  }

  return {
    sendRequest,
    error,
    isLoading,
  }
}

export default useHttp
