export const formArray = [
  {
    id: 1,
    name: 'personal-details',
    steps: [
      {
        id: 0,
        name: 'Personal Details',
        completed: false,
      },
      {
        id: 1,
        name: 'Id Documents - Summary',
      },
      {
        id: 2,
        name: 'Immigration history and Citizenships',
      },
      {
        id: 3,
        name: 'Family',
      },
      {
        id: 4,
        name: 'Relatives in Canada',
      },
    ],
  },
  {
    id: 2,
    name: 'study-and-languages',
    steps: [
      {
        id: 0,
        name: 'Education History',
        completed: false,
      },
      {
        id: 1,
        name: 'Official Languages',
      },
    ],
  },
  {
    id: 3,
    name: 'work-history',
    steps: [
      {
        id: 0,
        name: 'Canadian certificate of qualification',
        completed: false,
      },
      {
        id: 1,
        name: 'Intended work in Canada',
      },
      {
        id: 2,
        name: 'Work history',
      },
      {
        id: 3,
        name: 'Research',
      },
    ],
  },
  {
    id: 4,
    name: 'address-history',
    steps: [
      {
        id: 0,
        name: 'Address',
        completed: false,
      },
    ],
  },
  {
    id: 5,
    name: 'spouse-personal-details',
    steps: [
      {
        id: 0,
        name: 'Personal Details',
        completed: false,
      },
      {
        id: 1,
        name: 'Id Documents - Summary',
        completed: false,
      },
      {
        id: 2,
        name: 'Immigration history and Citizenships',
        completed: false,
      },
    ],
  },
  {
    id: 6,
    name: 'spouse-study-and-languages',
    steps: [
      {
        id: 0,
        name: 'Education History',
        completed: false,
      },
      {
        id: 1,
        name: 'Official Languages',
        completed: false,
      }
    ],
  },
  {
    id: 7,
    name: 'spouse-work-history',
    steps: [
      {
        id: 0,
        name: 'Canadian certificate of qualification',
        completed: false,
      },
      {
        id: 1,
        name: 'Intended work in Canada',
        completed: false,
      },
      {
        id: 2,
        name: 'Work history',
        completed: false,
      },
      {
        id: 3,
        name: 'Research',
        completed: false,
      }
    ],
  },
]
