import { useContext } from 'react'
import FormStepContext from '../store/form.step.context'
import { Navigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const StepMiddleWare = ({ children, pgtitle }) => {
  const formstep = useContext(FormStepContext)
  const location = useLocation()

  const step = location.pathname.split('/').pop()

  return (
    <>
      <Helmet>
        <title>{!!pgtitle ? pgtitle : ''} - Bee International Client Portal</title>
      </Helmet>
      {children}
    </>
  )
}

export default StepMiddleWare
