import { useContext } from 'react'
import AppHeader from './app.header'
import SubMenu from './secondary.menu'
import { Container } from '@mui/system'
import MainLoader from '../components/main.loader'
import AuthContext from '../store/auth.context'
const MainLayout = (props) => {
    const auth = useContext(AuthContext);
    return (
        <section className="main-layout">
            {auth.isLoading && <MainLoader />}
            <AppHeader />
            <SubMenu />
            <Container maxWidth="xl">{props.children}</Container>
        </section>
    )
}

export default MainLayout
