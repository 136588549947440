import useName from "../_hooks/use-name";

const ProfileMeta = () => {
    const name = useName()
    return (  <div className="profile-meta md:text-left text-center">
    <h3 className="mb-3 mt-2 text-lg font-medium ">{name} Profile</h3>
    <article className="text-sm md:text-inherit text-justify">
      Complete your online application by choosing a section below. <br/> You
      should review your application before you send it to make sure that it
      is complete and accurate.
    </article>
  </div> );
}

const SpouseProfileMeta = () => {
  return (  <div className="profile-meta md:text-left text-center">
  <h3 className="mb-3 mt-2 text-lg font-medium ">Spouse Profile</h3>
  <article className="text-sm md:text-inherit text-justify">
    Complete your online application by choosing a section below. <br/> You
    should review your application before you send it to make sure that it
    is complete and accurate.
  </article>
</div> );
}

const KidsDetails = () => {
  return(<div className="profile-meta md:text-left text-center">
  <h3 className="mb-3 mt-2 text-lg font-medium ">Kids Details</h3>
  <article className="text-sm md:text-inherit text-justify">
   Kids Details....
  </article>
</div>);
}
 
export {ProfileMeta, SpouseProfileMeta, KidsDetails};