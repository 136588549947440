import { Outlet } from 'react-router-dom'
import logo from '../logo.white.png'
import { formArray } from '../_data/formSteps.data'
import FormStepper from '../components/form-steper'
import { Snackbar, Alert } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import FormStepContext from '../store/form.step.context'
import { Link } from 'react-router-dom'
import AuthContext from '../store/auth.context'
import MainLoader from '../components/main.loader'

const FormLayout = ({ pgtitle }) => {
  const [steps, setSteps] = useState([])

  const form = useContext(FormStepContext)

  const auth = useContext(AuthContext)

  const currentForm = form.currentForm

  const { toast } = form

  const handleClose = () => form.resetToast()

  useEffect(() => {
    const newSteps = formArray.find((x) => x.id == currentForm.id).steps
    setSteps(newSteps)
  }, [currentForm])

  return (
    <>
      {auth.isLoading && <MainLoader />}
      <aside className="aside-menu">
        <figure className="w-36 md:absolute md:top-5 relative top-0">
          <img src={logo} />
        </figure>
        <FormStepper formsteps={steps} />
      </aside>
      <section className="user-form md:pt-0 pt-4">
        <div className="px-6 h-8 leading-8 text-right absolute top-3 right-3">
          <Link to="/dashboard">Goto Dashboard</Link>
        </div>
        <Outlet />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={toast.open}
          onClose={handleClose}
          autoHideDuration={1500}
        >
          <Alert
            severity={toast.status}
            sx={{ width: '100%' }}
            variant="filled"
          >
            {toast.message}
          </Alert>
        </Snackbar>
      </section>
    </>
  )
}

export default FormLayout
