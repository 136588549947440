import { useContext } from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { Check } from '@mui/icons-material'
import FormStepContext from '../store/form.step.context'
function StepperIcon(props) {
  const { active, completed } = props

  if (completed) {
    return (
      <div>
        <Check color="success" />
      </div>
    )
  } else if (active) {
    return <div className="in-compl active" />
  } else {
    return <div className="in-compl" />
  }
}

const FormStepper = ({ formsteps }) => {
  const formCtx = useContext(FormStepContext)

  const activeStep = formCtx.activeStepId
  return (
    <div className="md:inline hidden">
      <Stepper activeStep={activeStep} orientation="vertical">
        {formsteps.map((step, index) => (
          <Step key={step.id}>
            <StepLabel color="white" StepIconComponent={StepperIcon}>
              <span>{step.name}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}

export default FormStepper
