import { useContext } from "react";
import AuthContext from "../store/auth.context";
import { Navigate, useLocation } from "react-router-dom";
const AuthRoute = ({children}) => {
    const auth = useContext(AuthContext);
    const location = useLocation();
    if(!auth.userIsLoggedIn){
        return <Navigate to="/login" state={{from: location}} replace={true} />
    }
    return children;
    
}
 
export default AuthRoute;