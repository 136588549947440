import { useContext, useState } from "react"
import AuthContext from "../store/auth.context"


const useName = () => {
    const { user } = useContext(AuthContext);
    let name = "No Name"
    if (user != null) name = user.name
    return  name 
}

export default useName;

