import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { CircleOutlined } from '@mui/icons-material';
const FormCheck = ({status}) => {
    if(status == "Completed"){
        return ( <span><CheckCircleOutlineIcon color="success" fontSize='small' /></span> );
    }
    if(status == "Ongoing"){
        return ( <span><CheckCircleOutlineIcon color="warning" fontSize='small' /></span> );
    }
    return <span><CircleOutlined color="disabled" fontSize='small' /></span>
}
 
export default FormCheck;