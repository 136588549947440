import React from 'react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen text-center flex justify-center">
          <h3>Something went wrong!</h3>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
