import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import AuthRoute from './helper/_auth.route'
import StepMiddleWare from './helper/_step.route'
import LoginScreen from './screens/login'
import Dashboard from './screens/dashboard'
import { AuthContextProvider } from './store/auth.context'
import { FormStepContextProvider } from './store/form.step.context'
import MainLayout from './_layout/main.layout'
import FormLayout from './_layout/form.layout'
import { CircularProgress } from '@mui/material'

import './App.css'

const url = '/forms/personal-details'
const eduUrl = '/forms/study-and-languages'
const workUrl = '/forms/work-history'
const addrUrl = '/forms/address-history'


// spouse url
const spouseUrl = '/forms/spouse-personal-details'
const spouseEduUrl = '/forms/spouse-study-and-languages'
const spouseWorkUrl = '/forms/spouse-work-history'


// Candidate Personal
const PersonalDescription = React.lazy(() => import('./screens/forms/personal-details/personal.desc'))
const DocumentSummary = React.lazy(() => import('./screens/forms/personal-details/document.summary'))
const ImmigrationHistory = React.lazy(() => import('./screens/forms/personal-details/immigration.history'))
const FamilyData = React.lazy(() => import('./screens/forms/personal-details/family.data'))
const RelativeCanada = React.lazy(() => import('./screens/forms/personal-details/relatives.cad'))


// Candidate Study
const EducationHistory = React.lazy(() => import('./screens/forms/study-languages/education.history'))
const OfficialLanguage = React.lazy(() => import('./screens/forms/study-languages/official.language'))


// Work History
const CadCertifcate = React.lazy(() => import('./screens/forms/work-history/cad-certificate'))
const IntendWorkCad = React.lazy(() => import('./screens/forms/work-history/intend-cad'))
const WorkHistory = React.lazy(() => import('./screens/forms/work-history/work-history'))
const Research = React.lazy(() => import('./screens/forms/work-history/research'))

// Candidate Address
const AddressHistory = React.lazy(() => import('./screens/forms/address-detail'))

// Spouse Personal
const SpousePersonalDescription = React.lazy(() =>
  import('./screens/spouse/personal-details/personal.desc'),
)
const SpouseDocumentSummary = React.lazy(() =>
  import('./screens/spouse/personal-details/document.summary'),
)
const SpouseImmigrationHistory = React.lazy(() =>
  import('./screens/spouse/personal-details/immigration.history'),
)

// Spouse Education History
const SpouseEducationHistory = React.lazy(() =>
  import('./screens/spouse/study-languages/education.history'),
)
const SpouseOfficialLanguage = React.lazy(() =>
  import('./screens/spouse/study-languages/official.language'),
)
// Spouse Work History
const SpouseCadCertifcate = React.lazy(() =>
  import('./screens/spouse/work-history/cad-certificate'),
)
const SpouseIntendWorkCad = React.lazy(() =>
  import('./screens/spouse/work-history/intend-cad'),
)
const SpouseWorkHistory = React.lazy(() =>
  import('./screens/spouse/work-history/work-history'),
)
const SpouseResearch = React.lazy(() =>
  import('./screens/spouse/work-history/research'),
)

const KidsDetails = React.lazy(() => import('./screens/kids'))

function App() {
  return (
    <React.Suspense
      fallback={
        <div className="loader-wrap">
          <CircularProgress />
        </div>
      }
    >
      <AuthContextProvider>
        <FormStepContextProvider>
          <Routes>
            <Route path="login" element={<LoginScreen />} />
            <Route
              path="/dashboard"
              element={
                <AuthRoute>
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </AuthRoute>
              }
            />
            <Route
              path="/forms"
              element={
                <AuthRoute>
                  <FormLayout />
                </AuthRoute>
              }
            >
              <Route
                path={`${url}/step-1`}
                element={
                  <StepMiddleWare pgtitle={'Personal Details'}>
                    <PersonalDescription />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${url}/step-2`}
                element={
                  <StepMiddleWare pgtitle={'Id Document Summary'}>
                    <DocumentSummary />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${url}/step-3`}
                element={
                  <StepMiddleWare pgtitle={'Immigration History'}>
                    <ImmigrationHistory />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${url}/step-4`}
                element={
                  <StepMiddleWare pgtitle={'Family'}>
                    <FamilyData />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${url}/step-5`}
                element={
                  <StepMiddleWare pgtitle={'Relatives in Canada'}>
                    <RelativeCanada />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${eduUrl}/step-1`}
                element={
                  <StepMiddleWare pgtitle={'Education History'}>
                    <EducationHistory />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${eduUrl}/step-2`}
                element={
                  <StepMiddleWare pgtitle={'Official Languages'}>
                    <OfficialLanguage />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${workUrl}/step-1`}
                element={
                  <StepMiddleWare
                    pgtitle={'Canadian certificate of qualification'}
                  >
                    <CadCertifcate />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${workUrl}/step-2`}
                element={
                  <StepMiddleWare pgtitle={'Intended work in Canada'}>
                    <IntendWorkCad />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${workUrl}/step-3`}
                element={
                  <StepMiddleWare pgtitle={'Work history'}>
                    <WorkHistory />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${workUrl}/step-4`}
                element={
                  <StepMiddleWare pgtitle={'Research'}>
                    <Research />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${addrUrl}/step-1`}
                element={
                  <StepMiddleWare pgtitle={'Address'}>
                    <AddressHistory />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${spouseUrl}/step-1`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Personal Description'}>
                    <SpousePersonalDescription />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${spouseUrl}/step-2`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Document Summary'}>
                    <SpouseDocumentSummary />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${spouseUrl}/step-3`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Immigration'}>
                    <SpouseImmigrationHistory />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${spouseEduUrl}/step-1`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Education History'}>
                    <SpouseEducationHistory />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${spouseEduUrl}/step-2`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Official Language'}>
                    <SpouseOfficialLanguage />
                  </StepMiddleWare>
                }
              />

              <Route
                path={`${spouseWorkUrl}/step-1`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Cad Certificate'}>
                    <SpouseCadCertifcate />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${spouseWorkUrl}/step-2`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Details'}>
                    <SpouseIntendWorkCad />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${spouseWorkUrl}/step-3`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Work History'}>
                    <SpouseWorkHistory />
                  </StepMiddleWare>
                }
              />
              <Route
                path={`${spouseWorkUrl}/step-4`}
                element={
                  <StepMiddleWare pgtitle={'Spouse Work Research'}>
                    <SpouseResearch />
                  </StepMiddleWare>
                }
              />
            </Route>

            <Route path="forms/kids/details/step-1" element={<KidsDetails />} />

            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </FormStepContextProvider>
      </AuthContextProvider>
    </React.Suspense>
  )
}

export default App
