import React, { useState } from "react";

const AuthContext = React.createContext({
    token: '',
    user: {
        name: '',
        leadId: "",
        marital_status: "Single"
    },
    isLoggedIn: false,
    isLoading: false,
    login: (token) => {},
    logout: () => {},
    initLoading: () => {},
    resetLoading: () => {},
    storeUser: (user) => {}
});

export const AuthContextProvider = (props) => {

    const initialState = JSON.parse(localStorage.getItem('token'));
    const initialUser = JSON.parse(localStorage.getItem('user'));

    const [token, setToken] = useState(initialState);

    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState(initialUser);
    
    const [isUserLoggedIn, setUserIsLoggedIn] = useState(!!initialState);

    // Authinticate 
    const loginHandler = (token) => {
        setToken(token);
        setUserIsLoggedIn(true);
        localStorage.setItem('token', JSON.stringify(token));
    }

    const logoutHandler = () => {
        setToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('curForm');
    }

    // Handle Loader
    const handleLoader = () => setIsLoading(true);

    const handleReset = () => setIsLoading(false);

    // Handle User data

    const _updateUser = (user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
    }

    const contextVal = {
        token,
        user,
        isLoading,
        userIsLoggedIn: isUserLoggedIn,
        login: loginHandler,
        logout: logoutHandler,  
        storeUser: _updateUser,
        initLoading: handleLoader,
        resetLoading: handleReset
    }
    return <AuthContext.Provider value={contextVal}>{props.children}</AuthContext.Provider>
}

export default AuthContext;