export const ApiRoutes = {
  login: 'login',
  profile: 'profile',
  formStaus: 'file-status',
  initFormStatus: 'create-status',
  personalDetails: {
    personal_desc: 'personal-details',
    get_personal_desc: 'get-personal-details',
    create_id: 'create-id-documents',
    get_id_doc: 'get-id-documents',
    delete_id_doc: 'delete-id-documents',
    create_immg: 'create-immigration',
    get_immg: 'get-immigration',
    create_family: 'create-family',
    get_family: 'get-family',
    create_relatives: 'create-relatives',
    get_relatives: 'get-relatives',
  },
  study_lang: {
    create_edu_history: 'create-education-history',
    get_edu_history: 'get-education-history',
    delete_edu_history: 'delete-education-history',
    create_official_lang: 'create-official-languages',
    get_official_lang: 'get-official-languages',
  },
  work_history: {
    create_cad_certificate: 'create-canadaian-certificate',
    get_cad_certificate: 'get-canadaian-certificate',
    create_intend_work_cad: 'create-intend-work-cad',
    get_intend_wrk_cad: 'get-intend-work-cad',
    create_work_history: 'create-work-history',
    get_work_history: 'get-work-history',
    delete_work_history: 'deletework-history',
    create_work_research: 'create-work-research',
    get_work_research: 'get-work-research',
  },
  create_addres: 'create-address-index',
  get_address: 'get-address-index',
  delete_address: 'delete-address-index',
  spousePersonal: {
    spouse_create_personal: 'spouse-personal-details',
    get_spouse_prs_details: 'get-spouse-personal-details',
    spouse_creat_id_docs: 'spouse-create-id-documents',
    spouse_delete_id_docs: 'spouse-delete-id-documents',
    spouse_get_id_docs: 'spouse-get-id-documents',
    spouse_create_immg: 'spouse-create-immigration',
    spouse_get_immigration: 'spouse-get-immigration',
  },
  spouseStudy: {
    create_edu_history: 'spouse-create-education-history',
    get_edu_history: 'spouse-get-education-history',
    delete_edu_history: 'spouse-delete-education-history',
    create_official_lang: 'spouse-create-official-languages',
    get_official_lang: 'spouse-get-official-languages',
  },
  spouseWorkHistory: {
    create_cad_certificate: 'spouse-create-canadaian-certificate',
    get_cad_certificate: 'spouse-get-canadaian-certificate',
    create_intend_work_cad: 'spouse-create-intend-work-cad',
    get_intend_wrk_cad: 'spouse-get-intend-work-cad',
    create_work_history: 'spouse-create-work-history',
    delete_work_history: 'spouse-deletework-history',
    get_work_history: 'spouse-get-work-history',
    create_work_research: "spouse-create-work-research",
    get_work_research: "spouse-get-work-research"
  },

  create_kid_details: "create-kids_details",
  get_kid_detail: "get-kids_details",
  form_completed: "form-completed"
}
