import { Container } from "@mui/material"
import { Link } from "react-router-dom"
const SubMenu = () => {
    return (
        <div className="bg-white sm:py-3 mb-3 py-2">
            <Container maxWidth="xl">
                <ul className="submenu-list">
                    <li className="sm:inline hidden"><Link to="/">Home</Link></li>
                    <li className="disabled-item">Download Documents</li>
                    <li className="disabled-item">Download Study Materials</li>
                </ul>
            </Container>
        </div>
    )
}


export default SubMenu
