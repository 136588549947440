import { useState, useContext } from 'react'
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Avatar,
} from '@mui/material'
import logo from '../logo.white.png'
import AuthContext from '../store/auth.context'
import { useNavigate, useLocation } from 'react-router-dom'
const settings = ['Dashboard', 'Logout']

const AppHeader = () => {
  const [anchorElUser, setAnchorElUser] = useState(null)
  const auth = useContext(AuthContext)
  const { user } = auth
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget)
  }

  const navigate = useNavigate()

  const handleCloseUserMenu = (link) => {
    if (link === 'Logout') {
      auth.logout()
      navigate('/login', { replace: true })
    } else {
      navigate(link, { replace: false })
    }
    setAnchorElUser(null)
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <figure className="mr-auto w-36">
            <img src={logo} />
          </figure>
          <Box sx={{ flexGrow: 0 }}>
            {!!user && (
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Typography
                    variant="h5"
                    mr={2}
                    fontSize={15}
                    fontWeight={600}
                    color={'white'}
                  >
                    {user.name}
                  </Typography>
                  <Avatar alt={user.name} src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={handleCloseUserMenu.bind(this, setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default AppHeader
