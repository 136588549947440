import { useEffect, useContext, useState } from "react";
import { Card, LinearProgress } from "@mui/material";
import {
  ProfileMeta,
  SpouseProfileMeta,
  KidsDetails,
} from "../components/profile.meta";
import FormLineUp from "../components/formLineup";
import useHttp from "../_hooks/use-http";
import AuthContext from "../store/auth.context";
import { ApiRoutes } from "../config/apiroutes";
import { Helmet } from "react-helmet";
import { ConfigConst } from "../config";
import { Link } from "react-router-dom";

const KidsObj = {
  form: "kids/details",
  id: 1,
  status: "Completed",
};

const Dashboard = () => {
  const [formStep, setFormStep] = useState([]);
  const [spouseForm, setSpouseForm] = useState([]);
  const [kidsForm, setKidsForm] = useState([]);
  const Auth = useContext(AuthContext);

  const { profile, formStaus } = ApiRoutes;
  const { apiend } = ConfigConst;

  const handleResponse = (data) => {
    const user = {
      name: data.profile.first_name,
      leadId: data.lead_id,
      marital_status:
        data.marital_status != null ? data.marital_status : "Single",
    };
    Auth.storeUser(user);
    fetchFormStatsFun(data.lead_id);
  };
  const { sendRequest } = useHttp(handleResponse);

  const handleFormStats = (forms) => {
    setFormStep(forms.candidate);
    setSpouseForm(forms.spouse);
    setKidsForm(forms.kids);
  };

  const {
    sendRequest: fetchFormStats,
    isLoading,
    error,
  } = useHttp(handleFormStats);

  useEffect(() => {
    sendRequest({ url: profile, message: false });
  }, []);

  async function fetchFormStatsFun (leadId) {
    const response = await fetch(`${apiend}${formStaus}`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Auth.token}`,
      },
      body: JSON.stringify({lead_id: leadId})
    })
    const result = await response.json();
    handleFormStats(result);
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - Bee International Client Portal</title>
      </Helmet>

      <section className="max-w-6xl w-full mx-auto pb-4">
        <ProfileMeta />
        <Card variant="outlined" className="w-full mt-3 md:p-6 box-border p-3">
          <ul className="sm:grid grid-cols-4 gap-2 mb-4 hidden">
            <li className="col-span-2 font-medium">Forum Name</li>
            <li className="font-medium">Status</li>
            <li className="font-medium">Options</li>
          </ul>
          <div className="relative inner-child">
            {isLoading && <LinearProgress />}
            {formStep.map((form) => (
              <FormLineUp item={form} key={form.id} />
            ))}
          </div>
        </Card>
        {Auth.user.marital_status === "Married" && (
          <>
            <SpouseProfileMeta />
            <Card
              variant="outlined"
              className="w-full mt-3 md:p-6 box-border p-3"
            >
              <ul className="sm:grid grid-cols-4 gap-2 mb-4 hidden">
                <li className="col-span-2 font-medium">Forum Name</li>
                <li className="font-medium">Status</li>
                <li className="font-medium">Options</li>
              </ul>
              {spouseForm.map((item) => (
                <FormLineUp item={item} key={item.id} />
              ))}
            </Card>
            <KidsDetails />
            <Card className="w-full mt-3 md:p-6 box-border p-3">
             
              <div className="form-line-holder">
      <ul className="form-lineup">
        <li className="md:col-span-2 form-stat">
          Kids Details
        </li>
        <li></li>
        <li><Link
      to={"/forms/kids/details/step-1"}
      className="btn_theme"
     
    >
      Add Data
    </Link></li>
      </ul>
    </div>
            </Card>
          </>
        )}
      </section>
    </>
  );
};

export default Dashboard;
