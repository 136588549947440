import { Link } from 'react-router-dom'
import { useContext } from 'react'
import FormStepContext from '../../store/form.step.context'
import useHttp from '../../_hooks/use-http'
import { ApiRoutes } from '../../config/apiroutes'
const FormAction = ({ status, link, formStepper, type }) => {
  var linkTitle = 'Add Data'
  const {form_id} = formStepper;
  var customLink = type != null ? `${type} ${link}` : link
  const formLink = '/forms/' + customLink.replace(/ /g, '-').toLowerCase() + '/step-1'
  switch (status) {
    case 'On going':
      linkTitle = 'Continue Form'
      break
    case 'Completed':
      linkTitle = 'Update Form'
      break
    default:
      linkTitle = 'Add Data'
  }

  const form = useContext(FormStepContext)
  const { sendRequest } = useHttp(() => {})
  const { initFormStatus } = ApiRoutes
  const _handleformStepper = () => {
    if (status !== 'Completed') {
      sendRequest({
        url: initFormStatus,
        method: 'POST',
        body: { form: formStepper.form, status: 'OnGoing', form_id },
        message: false
      })
    }
    const formObj = {
      id: formStepper.form_id,
      form: formStepper.form.replace(/ /g, '-').toLowerCase(),
    }
    form.setCurrentForm(formObj)
  }

  return (
    <Link
      to={formLink}
      className="btn_theme"
      params={{ testvalue: 'hello' }}
      onClick={() => {
        form.setActiveStep(0)
        _handleformStepper()
      }}
    >
      {linkTitle}
    </Link>
  )
}

export default FormAction
