import FormStatus from "./form-status"
import FormCheck from "./form-check"
import FormAction from "./form-action"
const FormLineUp = ({item}) => {
  return (
    <div className="form-line-holder">
      <FormCheck />
      <ul className="form-lineup">
        <li className="md:col-span-2 form-stat">
          {item.form}
        </li>
        <li><FormStatus status={item.status} /></li>
        <li><FormAction status={item.status} link={item.form} formStepper={item} type={item.type}  /></li>
      </ul>
    </div>
  )
}


export default FormLineUp
