import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { formArray } from '../_data/formSteps.data'
import { useLocation } from 'react-router-dom'
const FormStepContext = React.createContext({
  currentForm: 1,
  formSteps: [],
  activeStepId: 0,
  toast: {
    open: false,
    message: '',
    status: 'info',
  },
  setFormSteps: (formStepArr) => {},
  setActiveStep: (id) => {},
  setCurrentForm: (formId) => {},
  showToast: ({ message, status }) => {},
  resetToast: () => {},
})

export default FormStepContext

export const FormStepContextProvider = (props) => {
  const [cookies, setCookie] = useCookies(['activeStep'])
  let active = parseInt(cookies.activeStep)
  let localCurForm = JSON.parse(localStorage.getItem('curForm'))

  let { pathname } = useLocation()
  const formPath = pathname.split('/')[2]
  const [formSteps, setFormSteps] = useState([])
  const [currentForm, setCurrentForm] = useState(
    !!localCurForm ? localCurForm : {id: 1, form: 'personal-details'},
  )
  const [activeStep, setActiveStep] = useState(!!active ? active : 0)
  const [toast, showToast] = useState({
    open: false,
    message: '',
    status: 'info',
  })

  const _setCurrentForm = (form) => {
    setCurrentForm(form)
    localStorage.setItem('curForm', JSON.stringify(form))
  }
  const _setFormSteps = (steps) => setFormSteps(steps)
  const _setActiveStep = (stepId) => {
    setActiveStep(stepId)
    setCookie('activeStep', stepId)
  }

  const _initToast = ({ message, status }) => {
    showToast({
      open: true,
      message,
      status,
    })
  }

  const _resetToast = () =>
    showToast({ open: false, message: '', status: 'info' })

  useEffect(() => {
    if (!!localCurForm && formPath !== localCurForm.form) {
      const newForm = formArray.find((x) => x.name === formPath);
      console.log(newForm);
      if(newForm){
        _setCurrentForm({id: newForm.id, form: newForm.name});
      }
    }
  }, [formPath])

  const formContextVal = {
    formSteps,
    currentForm,
    toast,
    activeStepId: activeStep,
    setFormSteps: _setFormSteps,
    setCurrentForm: _setCurrentForm,
    setActiveStep: _setActiveStep,
    showToast: _initToast,
    resetToast: _resetToast,
  }
  return (
    <FormStepContext.Provider value={formContextVal}>
      {props.children}
    </FormStepContext.Provider>
  )
}
